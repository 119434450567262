import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Insights from "../../molecules/Insights";

// Components
import StaffingHeader from "../../molecules/staffing/staffingHeader";
import StaffingDescription from "../../molecules/staffing/staffingDescription";
// import ContactForm from "../../molecules/ContactForm";
import ImageBanner from "../../components/atoms/banner/imageBanner";
import StaffingTechnicalCapabilities from "../../molecules/staffing/staffingTechnicalCapabilities";
import { useStaticQuery, graphql } from "gatsby";
import StaffingInterested from "../../molecules/staffing/staffingInterested";
import StaffingTestimonial from "../../molecules/staffing/staffingTestimonial";

//images
import bannerImg from "../../images/servicesassets/language.png";
import descImg from "../../images/servicesassets/language-description.png";

const LanguageServices = () => {
  const data = useStaticQuery(graphql`
    query GetLanguageServicesData {
      gcms {
        servicePages(where: { pageType: LANGUAGE_SERVICE }) {
          heading
          paragraph
          description
          timelineItems {
            heading
            paragraph
            listItem
            isList
          }
          technicalItems {
            heading
            paragraph
            icons {
              url
            }
          }
          sliderModals {
            tagline
            heading
            benefit
          }
          insightPages {
            shortDescription
            title
            slug
            image {
              url
            }
          }
        }
        languageManagementWhyGokenIcons {
          serviceTechnicalItems {
            heading
            paragraph
            icons {
              url
            }
          }
          heading
          paragraph
        }
      }
    }
  `);

  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO
        canonicalLink={
          "https://www.goken-global.com/services/languageservices/"
        }
        title={"Language Services: Language Services Offered by Goken"}
        pageName={"Language Services"}
        description={
          "Master global communication with Goken's expert language services. Translation, interpretation, and more. Click to expand your linguistic reach!"
        }
        // meta="Language services, Japanese language services, english to japanese, japanese to english, japanese translation, japanese interpretation, interpreter"
        facebookTitle={
          "Goken | Japanese Language Services | Best Translation & Interpretation Firm"
        }
        facebookDescription={
          "Japanese Language Services of Translation & Interpretation by Goken helps to build better interactions between English language speakers and Japanese speakers. Goken's Language Experts ensures high quality solution through Goken's Translation & Interpretation services."
        }
        keywords={
          "japanese language, language services, region, translation, interpretation, language barrier, communication, language experts, support, linguistics, textual translation services"
        }
      />
      <StaffingHeader
        heading={data.gcms.servicePages[0].heading}
        paragraph={data.gcms.servicePages[0].paragraph}
        image={bannerImg}
        btnTxt="Get In Touch"
        url="/contactus/"
      ></StaffingHeader>
      <StaffingDescription
        headingOne="About Goken’s Translation and INTERPRETATION"
        description={data.gcms.servicePages[0].description}
        image={descImg}
      ></StaffingDescription>
      <StaffingInterested heading="Language Services"></StaffingInterested>
      <StaffingTechnicalCapabilities
        heading="Why Language Services for your business?"
        listItems={data.gcms.servicePages[0].technicalItems}
      ></StaffingTechnicalCapabilities>
      <StaffingTestimonial></StaffingTestimonial>
      <StaffingTechnicalCapabilities
        heading={data.gcms.languageManagementWhyGokenIcons[0].heading}
        paragraph={data.gcms.languageManagementWhyGokenIcons[0].paragraph}
        listItems={
          data.gcms.languageManagementWhyGokenIcons[0].serviceTechnicalItems
        }
      ></StaffingTechnicalCapabilities>
      {/* <Insights insights={data.gcms.servicePages[0].insightPages}></Insights> */}
      {/* <ContactForm
        showForm={true}
        typeOfForm="Product or Service Inquiry"
      ></ContactForm> */}
      <ImageBanner
        heading="Learn more about Language Services"
        btnText="Get in Touch"
        link="/contactus/"
      ></ImageBanner>
    </Layout>
  );
};

export default LanguageServices;
